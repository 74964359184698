import './style.css';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="law">
				Все права защищены &copy;
			</div>
		</footer>
	);
}

export default Footer;